import React from 'react';
import './SliderCarousel.css';
import Logo from '../images/tn1.png'

const SliderCarousel = () => {

  return (
    <div className="slider-container">
      
      <div className="slider">
          <div className='sliderTopper'>
            <div className='sliderTopperLeft'>
              <h2>WORKING FOR COMMUNITIES AND MAKING COMMUNITIES WORK.</h2>
            </div>

            <div className='sliderTopperRight'>
              <img src={Logo} alt="" />

              <h2>The Newberry Institute</h2>
            </div>
          </div>

          <div className='sliderBottomer'>
            <div>
              <h1 style={{color: '#f5ffff'}}>Mission</h1>
              <p >The Mission of The Newberry Institute is to strengthen resilient communities through partnerships and purpose by preserving history, providing education, promoting interfaith dialogue and pathways to self reliance.</p>
            </div>
          </div>
      </div>

    </div>
  );
};

export default SliderCarousel;
