import React from 'react'
import './Mobile.css'
import { useNavigate } from 'react-router-dom'

function Mobile({ togActive2, handleToggle2 }) {
  const Navigate = useNavigate()
  return (
    <div className={`Mobile ${togActive2 && "down"}`}>
      <ul>
        <li onClick={()=>{
          Navigate('/')
          handleToggle2()
        }}>HOME</li>
        <li onClick={()=>{
          Navigate('/who-we-are')
          handleToggle2()
        }}>WHO WE ARE</li>
        <li onClick={()=>{
          Navigate('/what-we-do')
          handleToggle2()
        }}>WHAT WE DO</li>
        <li onClick={()=>{
          Navigate('/how-we-work')
          handleToggle2()
        }}>HOW WE WORK</li>
        <li onClick={()=>{
            Navigate('/usda-forest-service')
          }}>FOREST SERVICE</li>
        <li onClick={()=>{
          Navigate('/all-articles')
          handleToggle2()
        }}>ARTICLES</li>
        <li onClick={()=>{
          Navigate('/gallery')
          handleToggle2()
        }}>GALLERY</li>
        <li onClick={()=>{
          Navigate('/vote')
          handleToggle2()
        }}>Vote</li>
        <li onClick={()=>{
            Navigate('/kenya')
            handleToggle2()
        }}>KENYA</li>
        <li onClick={()=>{
          Navigate('/the-plow-and-pew-democracy-center')
          handleToggle2()
        }}>THE PLOW AND PEW DEMOCRACY CENTER</li>
        <li onClick={()=>{
          Navigate(`/rosa's-food-pantry`)
          handleToggle2()
        }}>ROSA’S FOOD PANTRY</li>
        <li onClick={()=>{
          Navigate(`/aniee-lee's-porch`)
          handleToggle2()
        }}>ANIEE LEE’S PORCH</li>
        <li onClick={()=>{
          Navigate(`/ameenah's-interfaith-center`)
          handleToggle2()
        }}>AMEENAH’S INTERFAITH CENTER</li>
        <li onClick={()=>{
          handleToggle2()
          window.open('https://www.paypal.com/donate/?hosted_button_id=KRNNUXHR4BLGG', '_blank');
        }}>DONATE</li>
      </ul>
    </div>
  )
}

export default Mobile
