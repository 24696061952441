import React, {useState} from 'react'
import './Footer.css'
import { Facebook, Instagram, Send, Twitter, YouTube } from '@mui/icons-material'
import Logo from "../../images/logo.png"
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


function Footer() {
  const Navigate = useNavigate();
  const location = useLocation();
  const routeName = location.pathname.slice(1);


  const linkStyle = {
    color: 'white',
    marginTop: '10px',
    fontSize: 'small'
  };


  const [formData, setFormData] = useState({
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Send a POST request to create a email
    axiosInstance
      .post(`/api/add-email`, formData)
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Email sent successfully.',
        });

        setFormData({
          email: '',
        })
        // Handle success (e.g., show a success message)
      })
      .catch(error => {
        console.error('Error creating email:', error);
        // Handle error (e.g., show an error message)
      });
  };

    // Get the current year using the Date object
    const currentYear = new Date().getFullYear();


  return (
    <div className='landingPageFooter'>
      <h2 style={{color: '#f5ffff'}}>Subscribe to Our Email list.</h2>
      <span></span>

      <div className='firstNth'>
        <input type='email' placeholder='Enter your email' className='overRide' name="email"
        value={formData.email}
        onChange={handleChange}
         />
        <Send className='rightIcon' onClick={handleSubmit} />
      </div>

      <div className='secondNth'>
        <div>
          {/* image */}
        </div>

        <div>
          <span>
            <a href="https://twitter.com/NewberryInst" target="_blank" rel="noopener noreferrer" className='footerLinkTag'>
              <Twitter />
            </a>
          </span>

          <span>
            <a href="https://www.facebook.com/newberryInstitute" target="_blank" rel="noopener noreferrer" className='footerLinkTag'>
              <Facebook />
            </a>
          </span>

          <span>
            <a href="https://www.youtube.com/@thenewberryinstitute" target="_blank" rel="noopener noreferrer" className='footerLinkTag'>
              <YouTube />
            </a>
          </span>
          <span>
            <a href="https://www.instagram.com/thenewberryinstitute" target="_blank" rel="noopener noreferrer" className='footerLinkTag'>
              <Instagram />
            </a>
            </span>
        </div>

        <div>
          {/* image */}
        </div>
      </div>


      <div className='thirdNth'>

        <div>
          <div>
            <img src={Logo} alt="" />
          </div>
          <div>
            <p>
              Building Resilient Communities Through Partnership and Purpose.
            </p>
          </div>
        </div>

        <div>

          <div>
            <h4 style={{color: '#f5ffff'}}>PAGES</h4>
            <p className={routeName === 'what-we-do' ? 'shadeLight' : ''} onClick={()=>{
              Navigate('/what-we-do')
            }}>What we do</p>
            <p className={routeName === 'where-we-work' ? 'shadeLight' : ''} onClick={()=>{
              Navigate('/how-we-work')
            }}>How we work</p>
            <p className={routeName === 'who-we-are' ? 'shadeLight' : ''} onClick={()=>{
              Navigate('/who-we-are')
            }}>Who we are</p>
            
          </div>

          <div>
            <h4 style={{color: '#f5ffff'}}>PROGRAMS</h4>
            <p className={routeName === 'providing-education' ? 'shadeLight' : ''} onClick={()=>{
              Navigate('/the-plow-and-pew-democracy-center')
            }}>The Plow & Pew Democracy Center</p>
            <p className={routeName === 'building-community' ? 'shadeLight' : ''} onClick={()=>{
              Navigate(`/rosa's-food-pantry`)
            }}>Rosa’s Food Pantry</p>
            <p className={routeName === 'preserving-history' ? 'shadeLight' : ''} onClick={()=>{
              Navigate(`/aniee-lee's-porch`)
            }}>Aniee Lee's Porch </p>
            <p className={routeName === 'providing-education' ? 'shadeLight' : ''} onClick={()=>{
              Navigate(`/ameenah's-interfaith-center`)
            }}>Ameenah’s Interfaith Center</p>
          </div>

          <div>
            <h4 style={{color: '#f5ffff'}}>CONTACT US</h4>
            <p>
              Consider volunteering virtually or in person. One of our staff will be in touch.
            </p>
            <p>
              <a href="mailto:info@thenewberryfoundation.org" target="_blank" rel="noopener noreferrer" style={linkStyle}>
                info@thenewberryfoundation.org
              </a>
            </p>
          </div>

        </div>

      </div>


      <div className='fourthNth'>
        <div>
          <p>Copyright {currentYear}</p>
        </div>

        <div>
          <p>The Newberry Institute</p>
        </div>
      </div>

    </div>
  )
}

export default Footer